import React from "react"
import "../pages/styles.scss"
import Header from "../components/header"

const ErrorPage  = () => {
    return (
        <div>        
           <Header/>
           <p>No hay contenido</p>
        </div>
    )
}


export default ErrorPage